<span> {{ countDownTitle }}: </span>
<span class="countDownDateTime">
  <ng-container *ngIf="daysToDday$ | async as daysToDday">
    <ng-container *ngIf="daysToDday && daysToDday > 0">
      <span>{{ daysToDday }}</span><span> Day</span><span *ngIf="daysToDday && daysToDday > 1">s</span><span>, </span>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="hoursToDday$ | async as hoursToDday">
    <ng-container *ngIf="hoursToDday && hoursToDday > 0">
      <span> {{ hoursToDday$ | async | number: "2.0" }}</span><span>:</span>
    </ng-container>
  </ng-container>
  <span>{{ minutesToDday$ | async | number: "2.0" }}</span><span>:</span>
  <span>{{ secondsToDday$ | async | number: "2.0" }}</span>
</span>
