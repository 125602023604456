<div class="icon-wrapper">
  <svg
    #icon
    id="svg-icon"
    viewBox="0 0 4251.971 595.281"
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    y="0px"
  >
    <g (click)="routeTo('www.kinderkultur.ch')">
      <rect class="svg-bg" height="595.281" width="4251.971"/>
      <path
        class="svg-fill svg-stroke"
        d="M496.497,483.741c0,0-13.132-95.12-11.582-102.388c1.55-7.27-74.83-6.636-80.114-2.666
		c-4.992,3.75-65.008,68.492-70.764,81.441c16.066-19.496,0.863,7.324,8.326,27.894c6.222,17.129-23.55,39.411-23.025,48.791
		C330.931,545.684,355.924,409.756,496.497,483.741z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M230.833,108.246c-0.933-10.25,1.678,48.882,3.728,52.498
		c-0.958-1.465-10.252-33.859-10.252-33.859s-11.493,19.881-18.327,27.649c-0.384,0.432,10.335,0.833,12.433,2.732
		c1.374,1.23,15.474,8.363,17.768,10.342c-0.113-0.045-0.187-0.071-0.187-0.071s0.896,0.646,1.232,0.853
		c3.322,2.101,36.819,18.194,38.428,19.644c1.491,1.349,4.328,7.058,2.186,4.909c-5.195-5.222-24.25-13.423-24.25-13.423
		s8.284,5.825,17.5,12.72c-5.582,0.593-28.513,19.824-29.378,24.708c-1.969,11.11,13.236-9.565,13.236-9.565
		s13.502-13.34,16.731-12.69c4.237,0.857,12.196,10.766,17.049,16.069c5.343,13.664,18.333,45.751,18.755,57.87
		c0.438,12.318,0.238,44.823-2.571,52.899c-0.167,0.266-0.343,0.584-0.552,0.961c0.446-0.754,0.854-1.444,1.215-2.067
		c-1.472,4.546-4.043,9.158-7.017,13.32c2.499-4.513,4.559-8.89,5.803-11.254c-2.594,4.444-6.471,10.966-10.051,16.653
		c-3.665,4.219-7.396,7.519-10.082,9.073c-5.205,3.038-23.49,6.55-33.178,7.767c-11.05-2.658-44.844-19.271-64.484-29.205
		c-4.208-12.613-13.609-39.399-19.281-40.868c-4.743-1.246-13.378-17.117-16.601-36.942c2.874-19.941,2.388-35.38,5.591-41.006
		c6.773-11.949,34.667-8.388,52.189-44.734c2.306-8.902-38.18-48.013-38.18-48.013s13.309,49.355,14.879,51.74
		c-4.375,2.407-44.731,4.661-44.731,4.661l17.705,23.92c-1.233-5.061-42.713-27.955-42.713-27.955l51.413-13.674
		c0,0-2.96-44.099-2.796-47.213c-9.089-4.891-54.362,41.623-70.207,70.513c-15.88,28.959,4.482,78.5,5.279,82.942
		c33.038,183.55-140.409,86.98-49.705,70.825c5.019-0.891-121.771-4.97,12.428,63.375c77.52,39.476,88.954-66.923,73.316-42.869
		c-15.642,24.051-15.423,220.408-48.305,161.222c-6.989-12.578,16.309,3.729,9.785-6.986c-14.427-23.7-48.457,24.98,28.585,49.942
		c19.875,6.437,11.175-22.138,33.54-70.597c2.907-16.622,26.183-72.729,26.017-72.218c-0.599,1.867,5.293,4.192,14.979,6.646
		c-2.371,3.395-30.126,36.023-33.282,68.684c-3.159,32.661,7.459,43.134-4.672,28.706c-12.135-14.429,3.182,54.796-4.525,25.7
		c-7.708-29.104-0.003-49.896,2.011-54.519c2.015-4.632,26.048-67.062,25.461-67.06c-4.528-0.029-32.446,65.354-34.53,70.156
		c-2.081,4.803-4.063,48.558-2.074,56.637c2,8.072,25.468,15.689,30.266,23.915c4.792,8.225,152.707-100.436,162.19-113.657
		c7.982-11.134,88.603-77.779,85.551-75.566c-10.703-6.577-12.45,1.541-8.377-12.719c39.546-24.016-49.201-36.384-49.201-36.384
		c5.818,4.171-14.017,102.491-26.11,105.036c-0.545,0.114-1.033,0.231-1.519,0.35c-1.251,1.712-2.432,2.77-3.538,3
		c-0.293,0.06-0.544,0.125-0.824,0.178c-0.682,0.63-1.346,1.036-1.977,1.171c-13.047,2.759-12.557,4.87-19.925,3.979
		c-7.394-0.871-66.498-12.583-80.584-14.393c-0.948-0.119-1.992-0.29-3.082-0.481c2.064-1.579,3.818-3.45,5.264-5.475
		c39.768,6.652,88.671,12.188,92.585,10.436c6.758-3.004,32.603-86.832,31.672-99.279c-0.925-12.454-2.389-18.067-4.703-16.456
		c-2.325,1.606-22.383,8.812-33.354,6.413c-9.402-2.064-12.355-27.228-13.004-34.339c0.41-4.675,0.741-8.398,0.804-9.153
		c0.216-2.351-4.345-36.578-4.345-36.578s-0.477-1.683-1.263-4.326l0.174,0.104c1.575,5.891,3.573,11.503,6.246,16.229
		c11.713,20.748,2.726,50.151,11.674,54.675c8.952,4.521,36.509,6.586,40.118,4.969c3.604-1.607,46.65,14.384,51.809,15.355
		c34.213,6.436,35.476-26.377,37.94-30.842c1.228-2.204-18.638,31.684-44.729,13.047c-15.838-11.312-18.641-29.736-18.641-39.142
		c0-21.435,38.314-44.563,44.115-60.886c5.783-16.324-9.588-46.096-19.885-50.325c-10.282-4.232-41.477,10.228-40.387,21.126
		c2.174,21.743,26.093,15.53,26.093,15.53s-22.055-11.495-3.102-19.258c11.568-4.742,18.327,2.483,18.327,11.182
		c0,3.963-5.255,27.633-39.763,28.892c-34.525,1.261-10.124-23.073-24.541-42.249c-14.417-19.18-23.3-11.183-55.917-41.007
		c-19.063-17.429-45.875-51.357-81.078-46.596c-35.212,4.768-47.373,6.947-44.422,11.498c3.689,2.385,38.522,28.884,38.522,28.884
		 M321.711,336.698c10.28,2.137,29.118-4.438,31.312-5.88c2.184-1.459,3.531,3.613,4.369,14.843
		c0.836,11.247-23.634,87.007-29.987,89.729c-3.792,1.643-52.52-3.575-90.399-9.748c2.508-3.834,4.06-8.005,4.926-11.296
		c1.881-7.144-11.88-32.768-23.241-48.863c0.047-0.408,0.07-0.793,0.024-1.106c-0.791-6.659-4.691-15.728-10.326-20
		c9.772,5.022,37.377,18.386,41.61,18.312c5.569-0.088,29.805-4.153,35.787-7.5c5.973-3.354,17.505-15.512,20.576-26.719
		c3.071-11.199,3.096-24.441,3.096-24.441S311.43,334.555,321.711,336.698z M308.32,306.672c0.253-2.936,0.543-6.148,0.81-9.212
		C308.986,300.016,308.745,303.204,308.32,306.672z M176.356,321.958l-1.938-0.961l-0.223-0.39
		c-0.005-0.017-0.013-0.026-0.015-0.047c0.063,0.03,0.109,0.059,0.172,0.1l0.057,0.175L176.356,321.958z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M578.58,310.251c8.493,4.606,16.939,5.28,19.643,1.791
		c-1.712,2.071-6.525,1.976-12.284,0.006c2.694-0.979,4.96-3.038,5.94-5.969c0.61-1.745,0.63-3.547,0.26-5.255
		c4.996,3.975,7.728,8.191,6.493,10.72c0.021-0.012,0.042-0.038,0.052-0.062c2.142-4.182-3.381-11.494-12.33-16.35
		c-8.954-4.862-17.96-5.411-20.111-1.227c0,0.017,0,0.04-0.021,0.052c1.338-2.434,6.209-2.499,12.138-0.559
		c-2.559,1.013-4.681,3.013-5.625,5.819c-0.613,1.816-0.618,3.679-0.193,5.433c-4.466-3.601-7.062-7.376-6.516-9.902
		C564.883,299.008,570.175,305.689,578.58,310.251z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M159.652,257.502c0,0,39.988-27.353,49.942-20.049c0,0,6.288,0.067,6.09-1.761
		C203.768,220.005,159.652,257.502,159.652,257.502z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M283.419,224.776c0,0-9.239-6.703-18.307-2.168c-0.161,0.074-0.312,0.159-0.469,0.243
		c-8.896,4.811-12.089,16.279-12.089,16.279s11.283,4.008,20.34-0.494c0.197-0.092,0.38-0.191,0.564-0.287
		C282.314,233.597,283.419,224.776,283.419,224.776z M270.614,223.771c0.169-0.011,0.331,0.018,0.485,0.074
		c0.087,0.03,0.166,0.091,0.249,0.14c0.043,0.031,0.096,0.054,0.136,0.093c0.113,0.087,0.202,0.192,0.282,0.312
		c0.002,0.01,0.009,0.015,0.015,0.022c0.086,0.136,0.149,0.282,0.197,0.444c0.089-0.271,0.251-0.499,0.518-0.521
		c0.345-0.017,0.663,0.322,0.686,0.755c0.036,0.448-0.25,0.826-0.578,0.858c-0.271,0.015-0.461-0.188-0.584-0.447l0,0
		c-0.026,0.166-0.068,0.321-0.136,0.466c-0.008,0.009-0.013,0.017-0.016,0.028c-0.065,0.128-0.139,0.25-0.231,0.349
		c-0.042,0.047-0.085,0.08-0.133,0.115c-0.071,0.06-0.141,0.13-0.227,0.172c-0.141,0.074-0.3,0.133-0.469,0.144
		c-0.743,0.051-1.391-0.576-1.459-1.398C269.301,224.541,269.872,223.83,270.614,223.771z M276.763,235.307
		c1.019-1.555,1.582-3.395,1.456-5.369c-0.203-3.179-2.133-5.823-4.834-7.208c4.778,0.642,8.363,3.418,8.363,3.418
		S281.227,231.171,276.763,235.307z M254.56,238.602c0,0,1.583-5.66,5.658-10.312c-0.277,0.949-0.428,1.945-0.358,2.979
		c0.304,4.656,4.237,8.164,8.989,8.213C261.744,241.139,254.56,238.602,254.56,238.602z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M198.938,254.031c-0.185,0-0.359-0.028-0.546-0.013c-1.544,0.116-2.946,0.604-4.176,1.321
		c-0.502,0.21-1.003,0.442-1.486,0.708c-8.881,4.821-11.928,16.854-11.928,16.854s11.357,4.217,20.23-0.605
		c1.82-0.99,3.379-2.291,4.737-3.719c0.151-0.156,0.297-0.314,0.432-0.479c4.925-5.49,6.75-12.661,6.75-12.661
		S206.138,252.919,198.938,254.031z M201.743,259.612c-0.262,0.023-0.455-0.165-0.579-0.411c-0.097,0.655-0.582,1.179-1.223,1.225
		c-0.745,0.055-1.393-0.545-1.455-1.355c-0.059-0.797,0.494-1.5,1.242-1.559c0.641-0.046,1.193,0.409,1.387,1.044
		c0.088-0.264,0.249-0.482,0.511-0.504c0.375-0.027,0.668,0.302,0.702,0.733C202.356,259.22,202.113,259.583,201.743,259.612z
		 M198.052,258.404c-0.057,0.222-0.232,0.376-0.401,0.349c-0.146-0.029-0.229-0.248-0.18-0.476c0.057-0.22,0.219-0.389,0.384-0.356
		C198.017,257.956,198.087,258.175,198.052,258.404z M200.702,271.803c-7.887,4.291-17.939,0.564-17.939,0.564
		s2.045-8.047,7.753-12.966c-0.552,1.231-0.85,2.572-0.744,3.985c0.359,4.801,4.782,8.383,9.915,8
		c0.93-0.07,1.806-0.305,2.636-0.615C201.808,271.144,201.271,271.496,200.702,271.803z M204.106,255.422
		c4.103,0.283,7.205,1.423,7.205,1.423s-0.928,3.596-3.24,7.448c0.153-0.738,0.248-1.498,0.186-2.283
		C208.055,259.208,206.42,256.839,204.106,255.422z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M246.359,267.101c2.322,7.694,15.129,18.179,15.129,18.179s-15.659-20.168-16.197-32.557
		C244.751,240.348,244.032,259.398,246.359,267.101z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M265.682,297.136c4.618-3.564,10.605-19.845,5.966-11.18
		c-4.628,8.677-15.729,11.19-28.642,15.728C230.094,306.225,261.054,300.698,265.682,297.136z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M233.757,318.652c-2.389,2.181,8.926,5.951,14.662,5.66
		c5.765-0.285,33.055-10.941,35.567-17.979c1.456-4.062-0.407-8.564-1.971-4.633c-2.671,6.703-26.088,16.049-36.438,18.242
		C235.216,322.131,235.204,317.325,233.757,318.652z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M291.166,298.225c-0.096-0.015-0.134-0.037-0.207-0.049
		c0.065,0.139,0.12,0.312,0.165,0.521C291.307,298.447,291.388,298.275,291.166,298.225z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M281.844,311.582c-8.144,7.752-29.723,14.119-30.254,14.134
		c-0.512,0.021,7.219,2.916,15.328-0.547c6.053-2.571,12.329-6.246,14.103-8.257C283.853,313.934,282.548,310.912,281.844,311.582z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M212.211,269.37c-2.118,4.203-21.572,10.877-21.572,10.877s18.313-3.555,19.883-5.476
		C212.09,272.869,214.323,265.177,212.211,269.37z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M276.708,245.023c5.424-2.373,11.411-13.438,3.175-3.747
		c-1.812,2.135-19.248,4.896-19.248,4.896S270.534,247.706,276.708,245.023z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M176.073,267.342c-4.482,9.991,12.637-16.091,21.493-15.889
		c30.477,0.699,6.074-2.051-0.485-1.752C190.518,249.999,176.073,267.342,176.073,267.342z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M286.899,219.229c-3.001-1.73-20.418-4.211-25.729-0.536
		c-1.47,1.023-2.896,2.918-4.19,5.108c3.889-4.091,10.313-6.861,21.012-5.486C283.247,219.559,289.174,220.538,286.899,219.229z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M256.743,224.918c-3.729,3.914-5.022,9.095-5.56,12.805
		C251.183,237.723,253.533,230.346,256.743,224.918z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M658.671,353.256c8.315,4.942,16.731,5.948,19.541,2.559
		c-1.773,2.009-6.586,1.717-12.278-0.481c2.744-0.866,5.082-2.835,6.186-5.726c0.652-1.719,0.737-3.521,0.438-5.232
		c4.841,4.159,7.417,8.488,6.093,10.967c0.018-0.027,0.043-0.042,0.061-0.062c2.297-4.083-2.956-11.622-11.71-16.832
		c-8.782-5.199-17.756-6.113-20.051-2.019c-0.017,0.022-0.011,0.061-0.017,0.07c1.413-2.381,6.27-2.241,12.108-0.086
		c-2.568,0.92-4.766,2.833-5.818,5.598c-0.686,1.773-0.754,3.635-0.403,5.407c-4.319-3.771-6.769-7.651-6.124-10.142
		C645.395,341.482,650.436,348.368,658.671,353.256z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M611.781,356.575c-4.279,7.94-1.976,10.872-1.976,10.872s-0.721-6.624,14.998-29.719
		C640.529,314.615,616.084,348.623,611.781,356.575z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M613.787,389.555c0.905-1.545-6.905,2.771-9.252,1.771
		c-5.483-2.314-12.021-6.447-16.993-9.846c-4.541-3.101-7.564-8.719-7.564-8.719s1.959,6.467,6.064,10.385
		c4.64,4.438,11.753,9.603,20.905,11.457C605.727,393.979,611.712,393.052,613.787,389.555z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M585.328,411.822c10.354,5.361,30.756,5.619,27.288,4.176
		c-24.885-10.337-34.622-13.71-50.207-31.078C545.161,365.688,573.238,405.57,585.328,411.822z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M561.82,390.223c0,0,0.917,10.918,6.779,17.822c9.11,10.729,3.85,8.723,21.397,12.456
		c3.543,0.767,14.087-2.41,14.087-2.41s-12.217-0.991-22.526-5.772C571.23,407.557,561.82,390.223,561.82,390.223z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M588.68,270.79c-8.464-4.871-14.781-7.511-17.382-7.418
		c-2.586,0.089-10.583,0.429-10.583,0.429s4.812,4.006,10.521,5.089c5.708,1.095,10.78,2.243,12.459,3.701
		c1.673,1.45,15.453,11.296,21.25,13.38c5.812,2.07,13.835,9.433,4.721-0.259C607.847,283.759,597.137,275.661,588.68,270.79z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M681.297,318.207c9.751,5.805,16.891,2.089,22.334,11.958
		c1.985,2.454,6.197,3.274,5.019-0.837c-1.169-4.146,0.671-3.189-5.229-7.512c-5.9-4.334-3.11-5.088-14.432-6.562
		c-11.308-1.468-32.085-9.301-33.22-8.849c-1.149,0.441-7.366-2.491-5.036,3.406C653.059,315.699,670.848,312.018,681.297,318.207z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M670.708,335.948c3.599,3.22,15.117,16.215,15.117,16.215s-11.163-16.095-17.279-19.265
		c-6.142-3.145-19.062-3.883-19.062-3.883S667.133,332.702,670.708,335.948z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M652.922,357.605c0.941,0.713,1.891,1.246,2.845,1.475
		C687.643,366.553,663.904,361.204,652.922,357.605z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M643.448,344.691c-0.592-1.221-0.942-1.89-0.942-1.89S642.852,343.548,643.448,344.691z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M648.148,355.512c0.172,0.457,2.108,1.225,4.773,2.096
		c-4.025-3.028-7.79-9.655-9.474-12.916C644.459,346.792,646.195,350.547,648.148,355.512z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M600.372,304.125c0,0,0.582,7.042-0.123-3.331c-0.696-10.033-53.824-22.325-22.65-12.495
		C586.556,291.126,596.969,295.797,600.372,304.125z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M588.343,319.354c0,0-3.527,0.224-7.662-0.145
		C586.009,320.859,588.343,319.354,588.343,319.354z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M580.681,319.209c-2.21-0.682-4.92-1.895-8.215-4.001
		c-3.826-2.446-5.993-4.327-7.224-5.752c0.769,1.75,2.003,3.882,4.081,5.998C571.697,317.868,576.495,318.835,580.681,319.209z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M565.241,309.457c-0.974-2.234-1.175-3.84-1.175-3.84S562.846,306.673,565.241,309.457z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M667.661,413.703c4.651-6.119,7.715-11.54,9.311-15.919
		C678.562,393.391,673.332,402.643,667.661,413.703z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M523.924,356.234c-0.032,0.168-0.062,0.688-0.074,1.449
		C524.223,355.834,524.258,355.084,523.924,356.234z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M638.291,441.24c-4.884,8.585-8.832,16.354-6.991,15.56
		c4.174-1.801-4.718,13.761,20.854,12.396c-15.276-3.021-9.003-16.631-12.752-14.78c-2.468,1.199,4.813-14.306,11.48-27.312
		C647.571,431.48,643.404,436.199,638.291,441.24z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M499.981,378.064c-3.235-3.385-3.235,4.188-1.457,15.957
		c0.306-1.832,1.054-2.396,2.41-1.18c13.522,12.312,21.208-10.549,22.759-17.025c0-0.616,0.021-1.051,0.068-1.188
		c0.314-0.956,0.288-0.321-0.068,1.188c-0.104,8.067,3.162,54.749,12.754,68.65c5.421,7.857,14.183,10.343,29.55,12.743
		c7.56,1.179,32.874-6.793,50.651-12.434c10.179-2.533,18.696-5.095,22.136-6.522c10.162-4.225,14.809-17.419,24.053-26.582
		c10.399-10.318,34.833-56.951,34.833-56.951s-34.445,46.543-41.448,55.537c-6.985,8.971-21.792,22.726-29.604,26.994
		c-7.808,4.266-52.547,13.316-53.748,13.036c-4.368-1.021-6.225-1.162-7.455-1.354c-0.905-0.29-1.752-0.613-2.555-0.958
		c-0.965-0.577-2.331-1.548-4.823-3.263c-1.73-1.201-3.191-2.292-4.533-3.357c-2.922-3.32-4.99-7.237-7.578-11.496
		c-2.071-3.406-4.209-7.979-6.296-13.178c-5.411-18.965-15.629-59.757-14.248-62.146c1.666-2.863-0.829-1.729-1.167,2.946
		c-0.381-0.549,0.465,5.602,1.91,14.561c-1.432-6.506-2.279-11.712-2.279-14.354C522.284,365.516,514.44,393.118,499.981,378.064z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M774.553,189.846c1.472-3.879-19.468-35.674-17.866-48.837
		c1.521-27.628,13.933-34.319,14.076-35.331c0.095-0.048,0.095-0.072,0.018-0.091c0.037-0.292-0.068-0.124-0.138,0.026
		c-0.906,0.125-30.254,15.026-35.066,12.689c-4.809-2.343-10.822-8-10.822-8s-12.783-16.064-1.607-46.971
		c2.746,1.625,5.9,2.564,9.271,2.575c10.475,0.033,18.958-8.875,18.958-19.893c-0.013-11.01-8.509-19.978-18.981-20.014
		c-10.489-0.031-18.982,8.875-18.977,19.889c0.01,7.315,3.763,13.707,9.356,17.204c-2.691,5.678-13.129,30.188-33.648,29.899
		c-9.766-0.136-6.17,2.079-15.324-3.839c-9.16-5.915-5.947-29.946-6.318-30.909c0.045-0.065,0.034-0.112-0.04-0.077
		c0-0.015,0.025-0.129,0.07-0.305c1.537,0.758,3.239,1.201,5.029,1.205c6.84,0.025,12.369-6.022,12.359-13.507
		c0-7.485-5.534-13.568-12.37-13.592c-6.84-0.021-12.368,6.025-12.368,13.513c0.012,5.495,3.011,10.218,7.305,12.358
		c-0.047,0.146-0.086,0.274-0.132,0.43c-9.134,27.953-13.229,30.763-21.622,34.437c-8.392,3.679-51.393,13.432-50.745,13.432
		c10.361-0.039,69.904-5.757,100.708,7.128C733.718,129.206,775.896,185.511,774.553,189.846z M768.721,107.537l-21.716,21.251
		c-0.019-0.007-0.024-0.03-0.024-0.03L768.721,107.537z M721.24,46.227c-0.007-8.603,3.111-11.711,11.017-11.685
		c7.913,0.028,9.005,2.418,9.011,11.017c0.017,8.599-1.438,10.705-9.346,10.682C724.015,56.212,721.249,54.818,721.24,46.227z
		 M722.223,65.001l-14.673,35.381c-0.023-0.013-0.05-0.027-0.07-0.036L722.223,65.001z M664.834,45.559
		c-0.012-6.301,3.127-7.363,8.007-7.343c4.877,0.015,5.017-0.292,5.013,6.008c0.004,6.298-1.618,9.063-9.349,7.343
		C665.562,49.703,664.834,50.059,664.834,45.559z M659.271,87.604l7.388-26.693C666.659,60.909,659.275,87.604,659.271,87.604z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M782.678,110.642c6.816,0.022,12.352-6.029,12.354-13.511
		c-0.006-7.483-5.542-13.567-12.38-13.589c-6.836-0.025-12.37,6.029-12.361,13.51C770.289,104.532,775.83,110.615,782.678,110.642z
		 M782.992,90.952c4.568,0.018,4.68,1.186,4.675,6.679c0.015,5.487-1.439,6.356-6.002,6.341c-4.573-0.017-3.687-1.186-3.674-6.673
		C777.979,91.805,778.428,90.939,782.992,90.952z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M558.179,170.46C558.389,170.057,558.401,169.976,558.179,170.46L558.179,170.46z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M532.206,164.838c-0.567,1.829-10.141,13.627-7.799,17.03
		c2.34,3.403,14.164,12.174,13.224,18.326c-0.946,6.147-19.379,11.455-20.931,18.722c-0.382,4.319,5.803,21.74,4.085,26.88
		c-1.703,5.133-5.985,17.967-21.184,15.163c1.161,1.704,37.57,22.243,32.686,26.9c-4.889,4.65-8.75,4.227-15.414,8.293
		c-6.681,4.073-11.594,17.668-4.614,22.515c6.983,4.851,5.438,7.466,5.438,7.466s-1.898,16.59,3.452,18.344
		c5.361,1.748,2.423,9.109,7.843,2.275c5.418-6.838,1.156-17.253,4.181-18.913c3.006-1.649,1.902-11.935,1.288-20.121
		c-0.609-8.179,10.848-51.232,15.299-56.926c4.458-5.696,24.914-7.218,30.949-15.182c6.038-7.959,2.971-11.664,7.477-21.297
		c4.52-9.633,11.242-12.994,16.607-11.248c5.357,1.749,1.907,11.659,8.048,14.421c6.129,2.762,23.799-8.205,33.448-9.636
		c9.668-1.437,14.097-2.835,15.271-10.435c1.173-7.596,1.328-5.46-3.514-9.75c-4.853-4.288-4.025,2.083-11.89-9.845
		c-7.868-11.933,0.3-10.034-6.341-19.555c-6.635-9.52-8.135-10.838-19.829-13.543c-11.713-2.706-45.634,0.888-53.419,3.974
		c-7.786,3.08-8.678,23.888-14.365,22.52c-5.676-1.365-11.045-3.106-9.699-8.572c1.342-5.463,12.678-3.092,12.114-5.91
		c-0.567-2.819-27.821-8.299-29.715-5.651C523.005,153.722,532.771,163.01,532.206,164.838z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M762.55,214.221c-5.39,0.221-12.194-6.604-13.6-18.658
		c-5.147,3.424-12.618,1.474-3.709-2.944c8.919-4.413,4.333-9.796,2.253-11.966c-2.085-2.166-8.066,8.825-12.347,7.715
		c-4.26-1.116,0.734-4.346-3.663-7.241c-4.391-2.892-12.722-6.92-13.032-11.187c-0.318-4.271,8.692-2.613,5.787-6.511
		c-2.903-3.894-4.984,3.409-10.44,0.418c-5.441-2.993-5.665-6.015-9.72-8.931c-4.05-2.919-9.462-0.725-9.226,2.473
		c0.237,3.202,0.535,9.617,2.407,9.119c1.89-0.499,7.457-3.059,7.457-3.059s-4.437-0.923-2.38-3.756
		c2.064-2.837,4.071,0.771,4.06,2.913c-0.021,2.15,4.01,4.71-4.386,6.76c-8.393,2.062-6.36,1.194-11.418-3.435
		c-5.056-4.634-31.158-11.098-37.415-10.811c-6.255,0.291-7.61,3.072-8.767,3.869c-1.162,0.807-0.016,6.977,0.659,8.894
		c0.651,1.92,5.084,9.812,7.878,12.289c2.797,2.473,8.795,10.429,11.977,10.908c3.167,0.479,0.508,9.26-3.558,13.139
		c-4.078,3.877-9.654,3.756-10.414,5.42c-0.749,1.67,0.166,2.133,2.421,4.47c2.271,2.334,7.532,40.216,12.124,40.946
		c4.589,0.729,17.106-9.141,14.534-6.09c-2.562,3.049-5.342,7.906-5.54,9.716c-0.228,1.808,41.426,46.266,41.426,46.266
		s-4.827,2.861-3.104,4.878c1.726,2.021,3.655-0.097,6.133,2.766c2.473,2.854,1.517,27.427-2.737,28.819
		c-4.265,1.386-9.748-6.792-12.67-6.217c-2.916,0.578,3.526,12.257,1.404,18.854c-2.132,6.602-9.337,8.033-10.841,13.684
		c-1.494,5.654-4.345,18.924-8.155,21.709c-3.802,2.785,24.385-27.379,37.062-32.142c12.678-4.754,4.976,31.05,12.475-18.146
		c7.512-49.19,52.631-34.129,40.194-38.926c-0.675-0.263-1.312-0.484-1.937-0.701c1.023-2.917,1.808-5.644,2.229-7.98
		c1.968-11.056-24.956-47.14-20.047-48.934c4.939-1.803,7.944-8.281,12.771-11.321c4.833-3.041-2.791,11.652-2.435,14.128
		c0.359,2.476,14.729-8.249,16.749-13.762c2.042-5.519,0.836-19.381,2.243-21.626c1.387-2.25,4.043-4.058,5.651-3.642
		c1.604,0.417-1.099,4.016-1.018,5.086c0.071,1.061,1.597,0.413,4.08-1.38c2.482-1.792,0.248-10.745-2.293-11.987
		c-2.52-1.244-8.968,8.354-13.068,11.701C774.491,213.151,767.93,213.996,762.55,214.221z M702.659,401.044
		c0.648,0.354,1.505-0.364,2.485-1.765 M769.426,298.258c-0.572-0.128-1.104-0.244-1.608-0.335c1.465-4.573,1.849-8.672,1.849-8.672
		S770.307,291.577,769.426,298.258z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M926.107,18.679c16.593,0-11.847,173.109,8.24,185.147
		c20.091,12.065,140.256-216.86,166.976-165.701c26.731,51.169-142.101,193.149-145.063,207.958
		c-5.246,26.155,209.471,149.672,142.149,168.362c-64.887,18.033-148.757-148.2-158.179-139.092
		c-9.438,9.096,8.761,131.618-22.982,137.786C847.183,426.734,857.168,18.679,926.107,18.679z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M1208.787,17.019c33.827-1.893,37.593,386.67,2.002,396.594
		C1179.694,422.27,1142.312,20.726,1208.787,17.019z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M1339.848,23.461c35.893-45.486,103.825,256.234,137.78,256.234
		c33.966,0-42.742-241.033,11.479-253.637c54.204-12.587,43.463,380.608,19.815,387.774
		c-23.626,7.146-96.361-193.438-127.097-197.221c-30.74-3.758-19.802,206.971-48.784,203.092
		C1304.064,415.768,1303.955,68.981,1339.848,23.461z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M1783.402,214.303c-24.094-85.84-139.646-197.663-166.527-194.644
		c-26.878,3.041-29.689,387.396,7.843,395.235C1662.229,422.723,1807.467,300.089,1783.402,214.303z M1722.248,248.378
		c-15.131,43.48-59.25,124.985-82.476,109.041c-14.536-9.921-1.18-239.684,22.047-240.099
		C1685.062,116.932,1734.463,213.198,1722.248,248.378z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M1848.788,31.287c27.761-25.074,153.276-9.197,162.167,13.47
		c8.889,22.687-87.202-3.742-110.575,18.625c-23.36,22.371-26.125,120.092-5.673,126.664c20.461,6.61,63.244-14.159,86.236,16.959
		c23.002,31.125-68.381,22.062-82.458,34.783c-14.064,12.735-11.751,97.36-1.889,121.329c9.855,23.962,105.741,3.885,117.978,27.048
		c12.238,23.146-152.579,31.208-163.68,21.259C1839.804,401.441,1821.036,56.36,1848.788,31.287z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M2107.561,191.537c4.901-17.009,101.215,8.621,127.884-14.283
		c12.548-11.462,13.34-110.492,0.841-130.04c-12.529-19.507-140.146-40.413-161.393-26.282
		c-21.246,14.135-21.399,395.434,8.759,395.434c30.153,0,7.571-150.137,14.649-160.2c7.08-10.11,136.596,167.654,172.098,150.775
		C2305.914,390.061,2102.659,208.519,2107.561,191.537z M2101.456,70.596c5.912-23.148,78.414-13.042,87.004,22.171
		c19.302,79.023-31.244,64.15-70.993,65.947C2098.694,158.206,2095.333,94.526,2101.456,70.596z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M2850.607,18.561c39.271-11.044-4.814,348.608,52.279,348.608
		c57.071,0,103.313,2.384,85.203,26.667c-18.126,24.331-129.912,21.521-149.104,15.119
		C2819.771,402.568,2811.373,29.594,2850.607,18.561z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M2938.273,51.987c-0.045-39.038,117.418-44.51,185.379-15.778
		c67.951,28.708-55.778,16.512-67.47,47.401c-11.682,30.902,37.856,325.519-9.456,324.246
		c-47.331-1.28-20.781-297.944-20.808-327.753C3025.883,50.3,2938.302,91.031,2938.273,51.987z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M3838.633,17.485c-190.899,46.453-55.039,383.078,2.851,398.123
		c57.878,15.05,107.508-17.633,43.214-43.888c-64.283-26.27-88.445-12.001-93.48-116.688c-5.032-104.71,11-180.621,66.26-167.746
		c55.242,12.93,9.133,56.337,38.926,56.337C3926.191,143.621,3918.742,17.485,3838.633,17.485z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M3999.893,413.73c46.207-1.92-1.771-142.213,13.885-158.904
		c15.611-16.7,97.951-14.623,104.994,7.016c7.021,21.616-26.127,148.717,10.688,153.651c36.838,4.946,39.997-348.489,1.83-387.763
		c-38.175-39.27-7.056,167.656-22.677,174.51c-15.648,6.87-89.061,6.755-101.602-7.005
		c-12.498-13.763,25.455-195.509-13.638-177.339C3954.29,36.093,3953.662,415.664,3999.893,413.73z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M2355.716,19.411c17.37,0-12.413,173.859,8.63,185.974
		c21.021,12.099,123.998-217.847,151.957-166.456c27.987,51.415-125.922,194.035-129.023,208.875
		c-5.473,26.275,219.297,150.333,148.814,169.141c-67.927,18.087-155.718-148.869-165.585-139.706
		c-9.878,9.103,9.157,132.192-24.063,138.358C2273.088,429.281,2283.551,19.411,2355.716,19.411z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M3474.668,194.307c4.996-17.004,103.855,8.641,131.229-14.274
		c12.89-11.477,13.693-110.64,0.891-130.203c-12.881-19.525-143.868-40.454-165.67-26.301
		c-21.795,14.129-21.961,395.882,8.98,395.882c30.945,0,7.766-150.315,15.036-160.382c7.285-10.124,140.216,167.848,176.655,150.944
		C3678.243,393.078,3469.624,211.305,3474.668,194.307z M3468.39,73.255c6.075-23.164,80.47-13.075,89.312,22.171
		c19.8,79.151-32.067,64.255-72.884,66.047C3465.562,160.958,3462.102,97.208,3468.39,73.255z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M602.652,525.472c-15.335,5.689-7.355,2.497-28.616,2.139
		c-21.263-0.359-73.853-34.745-81.72-48.45c-1.312-2.297-2.486-5.978-3.511-10.491c14.512,16.491,60.454,46.573,80.156,46.971
		c21.746,0.444,13.586,3.814,29.255-2.176c15.675-5.984,78.957-39.465,78.647-43.577c-0.303-4.109,22.657,2.257,38.977,23.808
		c16.303,21.526,30.782,80.998,24.456,73.659c-24.151-28.03,5.53-9.495-38.145-27.921"
      />
      <path
        class="svg-fill svg-stroke"
        d="M2593.625,17.195c36.898,0,12.734,311.38,45.929,311.38
		c33.162,0,70.695-39.857,74.543-52.686c3.903-12.831-8.759-215.067,21.975-249.282c30.747-34.202,45.325,388.42,21.944,389.338
		c-23.389,0.931-22.582-74.125-37.021-90.207c-14.437-16.084-112.132,98.957-129.108,80.076
		C2574.947,386.926,2556.729,17.195,2593.625,17.195z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M3196.588,22.458c36.912,0,12.736,307.251,45.934,307.251
		c33.159,0,70.694-39.302,74.553-51.977c3.891-12.667-8.771-212.237,21.969-245.984c30.729-33.761,45.319,383.287,21.936,384.197
		c-23.389,0.914-22.584-73.154-37.021-89.026c-14.438-15.866-112.132,97.655-129.081,79.032
		C3177.91,387.305,3159.689,22.458,3196.588,22.458z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M110.146,159.644c-4.114,0-7.454,2.994-7.454,6.684c0,3.693,3.339,6.683,7.454,6.683
		c4.121,0,7.458-2.99,7.458-6.683C117.604,162.637,114.266,159.644,110.146,159.644z M110.532,171.259
		c-2.626,0-4.754-1.912-4.754-4.267c0-2.353,2.128-4.262,4.754-4.262c2.629,0,4.757,1.909,4.757,4.262
		C115.29,169.347,113.161,171.259,110.532,171.259z"
      />
      <path
        class="svg-fill svg-stroke"
        d="M232.852,98.139c-4.116,0-7.453,2.993-7.453,6.685c0,3.69,3.337,6.684,7.453,6.684
		c4.118,0,7.455-2.994,7.455-6.684C240.307,101.131,236.97,98.139,232.852,98.139z M233.235,109.754
		c-2.626,0-4.756-1.91-4.756-4.267c0-2.354,2.13-4.266,4.756-4.266s4.758,1.911,4.758,4.266
		C237.994,107.844,235.863,109.754,233.235,109.754z"
      />
      <path
        class="svg-fill-stroke svg-stroke"
        d="M3460.105,354.27c1.925,1.513,7.946,1,10.848,0c2.893-1.006,3.85-1.263,4.814-4.028"
      />
      <path
        class="svg-fill"
        d="M3696.051,381.477c0-16.086,106.103-5.362,71.377,21.447
		C3732.704,429.734,3696.051,397.562,3696.051,381.477z"
      />
    </g>

    <g (click)="routeTo('www.schlosswochen.ch')">

      <rect class="svg-bg" height="139.65" width="1987.87" x="750" y="455.62"/>
      <path
        class="svg-fill-different"
        d="M871.958,570.141v-46.289h23.047c0.259,8.464,2.474,14.697,6.641,18.701
			c4.166,4.004,8.919,6.006,14.258,6.006c4.166,0,7.681-1.448,10.547-4.346c2.864-2.896,4.297-6.299,4.297-10.205
			c0-4.166-1.401-7.666-4.199-10.498c-2.8-2.832-7.916-6.058-15.349-9.677c-15.715-7.562-26.379-14.247-31.993-20.052
			s-8.42-13.631-8.42-23.479c0-11.869,3.711-21.065,11.133-27.587s15.853-9.782,25.293-9.782c10.806,0,19.173,4.216,25.098,12.646
			v-11.279h22.949v41.309H932.31c-0.717-6.38-2.915-11.312-6.592-14.795c-3.679-3.482-7.959-5.225-12.842-5.225
			c-3.776,0-6.967,1.01-9.57,3.027c-2.604,2.019-3.906,4.655-3.906,7.91c0,2.8,1.074,5.453,3.223,7.959
			c2.148,2.507,8.437,6.06,18.864,10.658c10.038,4.471,17.737,8.513,23.102,12.129c5.363,3.617,9.605,8.309,12.727,14.075
			c3.121,5.768,4.682,12.43,4.682,19.987c0,13.425-3.906,23.524-11.719,30.301s-17.058,10.164-27.734,10.164
			c-11.914,0-21.094-4.41-27.539-13.232v11.572H871.958z"
      />
      <path
        class="svg-fill-different"
        d="M1090.806,434.301h22.949v47.852h-22.949c-6.511-17.188-17.807-25.781-33.887-25.781
			c-13.281,0-23.325,4.525-30.127,13.574c-6.804,9.05-10.205,19.89-10.205,32.52c0,13.217,3.597,23.91,10.791,32.08
			c7.193,8.171,16.357,12.256,27.49,12.256c9.114,0,17.09-3.092,23.926-9.277c6.836-6.185,11.458-14.778,13.867-25.781
			l26.758,12.744c-10.156,31.543-32.52,47.314-67.09,47.314c-24.414,0-42.822-6.575-55.225-19.727
			c-12.402-13.15-18.604-29.785-18.604-49.902c0-14.322,3.223-26.855,9.668-37.598s15.12-18.831,26.025-24.268
			c10.904-5.436,22.867-8.154,35.889-8.154c16.211,0,29.785,4.232,40.723,12.695V434.301z"
      />
      <path
        class="svg-fill-different"
        d="M1130.942,570.141v-22.949h16.992V457.25h-16.992v-22.949h65.625v22.949h-14.258v29.785h37.012V457.25
			h-13.77v-22.949h65.137v22.949h-16.992v89.941h16.992v22.949h-65.137v-22.949h13.77v-37.109h-37.012v37.109h14.258v22.949
			H1130.942z"
      />
      <path
        class="svg-fill-different"
        d="M1282.31,570.141v-22.949h19.434V457.25h-19.434v-22.949h74.609v22.949h-19.824v89.941h40.137v-41.992
			h26.465v64.941H1282.31z"
      />
      <path
        class="svg-fill-different"
        d="M1491.294,432.152c23.438,0,41.878,6.771,55.322,20.312c13.443,13.542,20.166,30.046,20.166,49.512
			c0,19.531-6.738,36.052-20.215,49.561c-13.477,13.51-31.901,20.264-55.273,20.264c-24.284,0-42.937-6.949-55.957-20.85
			c-13.021-13.899-19.531-30.225-19.531-48.975c0-19.4,6.689-35.889,20.068-49.463S1467.726,432.152,1491.294,432.152z
			 M1491.294,456.957c-10.417,0-19.255,3.906-26.514,11.719c-7.26,7.812-10.889,19.271-10.889,34.375
			c0,13.347,3.58,24.023,10.742,32.031c7.161,8.008,16.048,12.012,26.66,12.012c10.416,0,19.271-3.922,26.562-11.768
			c7.291-7.845,10.938-19.156,10.938-33.936c0-13.216-3.483-23.926-10.449-32.129
			C1511.378,461.059,1502.361,456.957,1491.294,456.957z"
      />
      <path
        class="svg-fill-different"
        d="M1586.216,570.141v-46.289h23.047c0.26,8.464,2.474,14.697,6.641,18.701
			c4.166,4.004,8.919,6.006,14.258,6.006c4.166,0,7.682-1.448,10.547-4.346c2.864-2.896,4.297-6.299,4.297-10.205
			c0-4.166-1.4-7.666-4.199-10.498c-2.8-2.832-7.916-6.058-15.349-9.677c-15.715-7.562-26.38-14.247-31.993-20.052
			c-5.614-5.805-8.42-13.631-8.42-23.479c0-11.869,3.711-21.065,11.133-27.587s15.853-9.782,25.293-9.782
			c10.807,0,19.173,4.216,25.098,12.646v-11.279h22.949v41.309h-22.949c-0.717-6.38-2.914-11.312-6.592-14.795
			c-3.679-3.482-7.959-5.225-12.842-5.225c-3.776,0-6.967,1.01-9.57,3.027c-2.604,2.019-3.906,4.655-3.906,7.91
			c0,2.8,1.074,5.453,3.223,7.959c2.148,2.507,8.437,6.06,18.864,10.658c10.037,4.471,17.737,8.513,23.102,12.129
			c5.364,3.617,9.605,8.309,12.728,14.075c3.121,5.768,4.682,12.43,4.682,19.987c0,13.425-3.906,23.524-11.719,30.301
			s-17.058,10.164-27.734,10.164c-11.914,0-21.094-4.41-27.539-13.232v11.572H1586.216z"
      />
      <path
        class="svg-fill-different"
        d="M1692.368,570.141v-46.289h23.047c0.26,8.464,2.474,14.697,6.641,18.701
			c4.166,4.004,8.919,6.006,14.258,6.006c4.166,0,7.682-1.448,10.547-4.346c2.864-2.896,4.297-6.299,4.297-10.205
			c0-4.166-1.4-7.666-4.199-10.498c-2.8-2.832-7.916-6.058-15.349-9.677c-15.715-7.562-26.38-14.247-31.993-20.052
			c-5.614-5.805-8.42-13.631-8.42-23.479c0-11.869,3.711-21.065,11.133-27.587s15.853-9.782,25.293-9.782
			c10.807,0,19.173,4.216,25.098,12.646v-11.279h22.949v41.309h-22.949c-0.717-6.38-2.914-11.312-6.592-14.795
			c-3.679-3.482-7.959-5.225-12.842-5.225c-3.776,0-6.967,1.01-9.57,3.027c-2.604,2.019-3.906,4.655-3.906,7.91
			c0,2.8,1.074,5.453,3.223,7.959c2.148,2.507,8.437,6.06,18.864,10.658c10.037,4.471,17.737,8.513,23.102,12.129
			c5.364,3.617,9.605,8.309,12.728,14.075c3.121,5.768,4.682,12.43,4.682,19.987c0,13.425-3.906,23.524-11.719,30.301
			s-17.058,10.164-27.734,10.164c-11.914,0-21.094-4.41-27.539-13.232v11.572H1692.368z"
      />
      <path
        class="svg-fill-different"
        d="M1841.978,571.02l-38.77-113.77h-14.355v-22.949h61.23v22.949h-14.062l22.07,65.234l20.703-65.234h-12.305
			v-22.949h48.828v22.949h-12.598l22.266,65.234l21.387-65.234h-13.574v-22.949h54.395v22.949h-14.746l-37.5,113.77h-25.391
			l-21.387-62.012l-20.215,62.012H1841.978z"
      />
      <path
        class="svg-fill-different"
        d="M2057.407,432.152c23.438,0,41.878,6.771,55.322,20.312c13.443,13.542,20.166,30.046,20.166,49.512
			c0,19.531-6.738,36.052-20.215,49.561c-13.477,13.51-31.901,20.264-55.273,20.264c-24.284,0-42.937-6.949-55.957-20.85
			c-13.021-13.899-19.531-30.225-19.531-48.975c0-19.4,6.689-35.889,20.068-49.463S2033.839,432.152,2057.407,432.152z
			 M2057.407,456.957c-10.417,0-19.255,3.906-26.514,11.719c-7.26,7.812-10.889,19.271-10.889,34.375
			c0,13.347,3.58,24.023,10.742,32.031c7.161,8.008,16.048,12.012,26.66,12.012c10.416,0,19.271-3.922,26.562-11.768
			c7.291-7.845,10.938-19.156,10.938-33.936c0-13.216-3.483-23.926-10.449-32.129
			C2077.491,461.059,2068.475,456.957,2057.407,456.957z"
      />
      <path
        class="svg-fill-different"
        d="M2265.024,434.301h22.949v47.852h-22.949c-6.511-17.188-17.807-25.781-33.887-25.781
			c-13.281,0-23.324,4.525-30.127,13.574c-6.804,9.05-10.205,19.89-10.205,32.52c0,13.217,3.597,23.91,10.791,32.08
			c7.193,8.171,16.357,12.256,27.49,12.256c9.114,0,17.09-3.092,23.926-9.277c6.836-6.185,11.458-14.778,13.867-25.781
			l26.758,12.744c-10.156,31.543-32.52,47.314-67.09,47.314c-24.414,0-42.822-6.575-55.225-19.727
			c-12.402-13.15-18.604-29.785-18.604-49.902c0-14.322,3.223-26.855,9.668-37.598s15.12-18.831,26.025-24.268
			c10.904-5.436,22.867-8.154,35.889-8.154c16.211,0,29.785,4.232,40.723,12.695V434.301z"
      />
      <path
        class="svg-fill-different"
        d="M2305.161,570.141v-22.949h16.992V457.25h-16.992v-22.949h65.625v22.949h-14.258v29.785h37.012V457.25
			h-13.77v-22.949h65.137v22.949h-16.992v89.941h16.992v22.949h-65.137v-22.949h13.77v-37.109h-37.012v37.109h14.258v22.949
			H2305.161z"
      />
      <path
        class="svg-fill-different"
        d="M2457.603,570.141v-22.949h16.309V457.25h-16.309v-22.949h119.727v43.066h-25.977V457.25h-42.09v31.348
			h33.984v22.949h-33.984v35.645h42.09v-20.312h25.977v43.262H2457.603z"
      />
      <path
        class="svg-fill-different"
        d="M2589.731,570.141v-22.949h18.164V457.25h-18.164v-22.949h57.059l49.973,84.705V457.25h-17.676v-22.949
			h60.938v22.949h-19.043v113.867h-30.273l-58.594-100.684v76.758h17.09v22.949H2589.731z"
      />
    </g>
  </svg>

  <router-outlet></router-outlet>
</div>
