<app-wizard-step
  (nextStepClicked)="goToNextStep()"
  (previousStepClicked)="goToPreviousStep()"
  [previousEnabled]="false"
  [title]="title"
>
  <div class="wizard-content">
    <div class="wizard-content-body">
      <div class="wizard-content-body">
        <form novalidate>
          <div class="form-group mb-2">
            <app-header [showAmount]="false" [showCountdown]="false" [showWeek]="false"></app-header>
          </div>
          <div class="form-group mb-2">
            <div [innerHtml]="welcomeText | translate | safe: 'html'" class="welcome-text"></div>
          </div>
        </form>
      </div>
      <!--div *ngIf="loggedIn$ | async as loggedIn; else loginContent">
        <p>
          <button class="btn btn-outline-danger" (click)="logout()">
            {{ "Logout" | uppercase | translate }}
          </button>
          is logged in {{ loggedIn }}
        </p>
        <div *ngIf="profile$ | async as profile">
          <img [src]="profile.picture" />
          <pre>{{ profile | json }}</pre>
        </div>
      </div>

      <ng-template #loginContent>
        <button class="btn btn-outline-success" (click)="login()">Login</button>
      </ng-template-->
    </div>
  </div>
</app-wizard-step>
