<section>
  <header>
    <div *ngIf="loading$ | async" class="loader-background">
    </div>
    <app-loading-indicator></app-loading-indicator>
  </header>

  <div class="content text-light">
    <button
      (click)="goToPreviousStep()"
      [class.disabled]="!previousEnabled"
      class="btn btn-primary mr-3"
      id="previous-button"
    >
      <i class="bi-chevron-left"></i>
      <span>{{ "WIZARD.PREVIOUS" | translate }}</span>
    </button>

    <ng-content></ng-content>

    <ng-container *ngIf="saveStep">
      <button
        (click)="goToSaveStep()"
        class="btn btn-primary mr-3"
        id="save-button"
      >
        <span>{{ "WIZARD.SAVE" | translate }}</span> <i class="bi-save space"></i>
      </button>
    </ng-container>
    <ng-container *ngIf="exitStep">
      <button
        (click)="goToExitStep()"
        class="btn btn-primary mr-3"
        id="exit-button"
      >
        <span>{{ "WIZARD.EXIT" | translate }}</span> <i class="bi-check2 space"></i>
      </button>
    </ng-container>
    <ng-container *ngIf="!saveStep && !exitStep">
      <button
        (click)="goToNextStep()"
        [class.disabled]="!nextEnabled"
        class="btn btn-primary mr-3"
        id="next-button"
      >
        <span>{{ "WIZARD.NEXT" | translate }}</span>
        <i class="bi-chevron-right"></i>
      </button>
    </ng-container>
  </div>
  <div class="footer"></div>
</section>
