<div class="alert alert-info show language-chooser">
  {{ "LANGUAGE" | translate }}:
  <select #langSelect (change)="translate.use(langSelect.value)">
    <option
      *ngFor="let lang of translate.getLangs()"
      [selected]="lang === translate.currentLang"
      [value]="lang"
      class="alert-warning"
    >
      {{ lang }}
    </option>
  </select>
</div>
<ng-container *ngIf="showWeek">
  <div
    *ngIf="week$ | async as week"
    class="alert alert-primary show"
    role="alert"
  >
    <span>{{ "WEEKNR" | translate }} {{ week?.week }} <wbr /> </span
    ><span>({{ week?.dateFrom | date: "dd.MM.":"de-CH" }}-</span
    ><span>{{ week?.dateTo | date: "dd.MM.YYYY":"de-CH" }})</span>
  </div>
</ng-container>
<div *ngIf="inscription">
    <div *ngIf="showAmount"
      [ngClass]="
        inscription.state?.toLowerCase()?.includes('waitinglist')
          ? 'alert-warning'
          : 'alert-success'
      "
      class="alert show"
      role="alert"
    >
      <span *ngIf="inscription.numOfChildren! > 0">
        {{ "RESERVEDPLACES" | translate }}{{ inscription.numOfChildren }}
      </span>
      <span *ngIf=" inscription.state?.toLowerCase()?.includes('waitinglist')" class="reserved-places">
        ({{ "WAITINGLIST" | translate }})
      </span>
    </div>
  <div *ngIf="externalInput" class="alert alert-warning show" role="alert">
    <span>{{ externalInput }}</span>
  </div>
  <div
    *ngIf="
      showCountdown && !(loggedIn$ | async) && (deadline$ | async) as deadline
    "
    class="alert alert-info show"
    role="alert"
  >
    <app-count-down [dDay]="deadline" [title]="'TIMELEFT'"></app-count-down>
  </div>
</div>

<h1>{{ title | translate }} {{ titlePostfix }}</h1>
