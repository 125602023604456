export const environment = {
   production: true,
   API_KEY_IMAGE4IO: "undefined",
   API_SECRET_IMAGE4IO: "undefined",
   API_URL_IMAGE4IO: "undefined",
   APP_ID_REALM: "data-pcuoo",
   NODE_VERSION: "v16.20.2",
   APP_ID_REALM_READWRITE: "schlosswochen-inscription-fggvp",
   MAX_NUMBER_OF_RESERVATIONS: "3",
   MAX_NUMBER_OF_WEEKS: "3",
   UPCOMING_YEAR: "2024",
   CONTEXT: "production",
   OPENINGSOON: "false"
};